import React from 'react';
import Layout from '../components/Layout/Layout';
import Plans from '../components/Plans/Plans';

const Planes = ({...props }) => {
    return (
        <Layout title="Planes" {...props}>
            <Plans fullData={true} elianaPrice={true} />
        </Layout>
    );
};


export default Planes;
